import KeyMirror from "keymirror"
import { getLookups } from "./utils"

export const dialogTypes = KeyMirror({
    // На странице регистрации
    PHONE_CONFIRMATION: null,
    PERSONAL_DATA_CONFIRMATION: null,

    ENTRANT_APPLICATION: null,
    ENTRANT_APPLICATION_REWORK: null,
    ENTRANT_APPLICATION_REJECT: null,
    ENTRANT_APPLICATION_TO_FAIL_COMPETITION: null,
    USER_FORM: null,
    ENTRANT_FORM: null,
    ENTRANT_APPLICATIONS_OPERATOR: null,
    ENTRANT_APPLICATION_COMPETITIVE_GROUP_HISTORY: null,
    ENTRANT_APPLICATION_DEP_CODE: null,
    HIDE_COLUMNS: null,
    SCORECARD: null,
    SCORECARD_CLOSE: null,
    SCORECARD_COPY: null,
    ORDER: null,
    ORDER_CLOSE: null,
    RATING: null,
})

export const dialogModes = KeyMirror({
    VIEW: null,
    CREATE: null,
    UPDATE: null,
    DELETE: null,
})

export const applicationStatuses = [
    { id: 1, name: "Черновик" },
    { id: 2, name: "Новое из ЕПГУ" },
    { id: 3, name: "Подано" },
    //{ id: 4, name: "Доработка" },
    { id: 5, name: "Доработка" },
    { id: 6, name: "Принято" },
    { id: 7, name: "Участвует в конкурсе" },
    { id: 8, name: "Отклонено" },
    { id: 9, name: "Отозвано" },
    { id: 10, name: "Включен в приказ" },
    { id: 11, name: "Не прошел по конкурсу" },
    { id: 12, name: "Отказ от зачисления" },
    { id: 13, name: "Зачислен" },
]

export const applicationStatusLookups = getLookups(applicationStatuses)

export const submitDocWays = [
    { id: 1, name: "Лично" },
    { id: 2, name: "Через операторов почтовой связи" },
    { id: 3, name: "Электронно" },
    { id: 4, name: "Доверенному лицу" },
    { id: 5, name: "ЕПГУ" },
]

export const submitDocWayLookups = getLookups(submitDocWays)

export const olympResults = [
    { id: 1, name: "Победитель" },
    { id: 2, name: "Призер" },
    { id: 3, name: "Лауреат" },
]

export const olympResultLookups = getLookups(olympResults)

export const militaryCategories = [
    { id: "А", name: "А - годен к военной службе" },
    { id: "Б", name: "Б  - годен к службе с незначительными ограничениями" },
    { id: "В", name: "В - ограниченно годен к военной службе" },
    { id: "Г", name: "Г - временно не годен" },
    { id: "Д", name: "Д - не годен к несению военной службы" }
]

export const militaryDocTypes = [
    { id: 1, name: "Приписное свидетельство" },
    { id: 2, name: "Военный билет" }
]

export const invalidGroups = [
    { id: "I", name: "I группа" },
    { id: "II", name: "II группа" },
    { id: "III", name: "III группа" },
    { id: "child", name: "Ребенок-инвалид" },
]

export const eduForms = [
    { id: 1, name: "Очная" },
    { id: 2, name: "Заочная" },
    { id: 3, name: "Очно-заочная" },
]

export const eduFormLookups = getLookups(eduForms)

export const eduTypes = [
    { id: 1, name: "Бюджетная" },
    { id: 2, name: "С компенсацией затрат" },
    { id: 3, name: "Целевое обучение" },
    { id: 4, name: "Особая квота" },
    { id: 5, name: "Отдельная квота" },
]

export const eduTypeLookups = getLookups(eduTypes)

export const originalTypeLookups = {
    not_submitted: "Не подан/Отозван",
    fake: "электронный",
    real: "бумажный",
}

export const thirdPartyApprovalStatusLookups = {
    1: "Не проверен",
    3: "Подтвержден в ФРДО",
    5: "Подтвержден ЕПГУ",
    6: "Подтвержден в ФИС ГИА и приема",
}

export const operatorRoles = {
    OPERATOR: 1,
    COMMISSION_MEMBER: 2,
    DICT_EDITOR: 3,
    ADMIN: 4,
    DOC_EDITOR: 5,
    CHECK_SAT_EXAM: 6,
    RATINGS: 7
}

export const originalLookups = {
    not_submitted: "Не подан или отозван",
    fake: "Подан через ЕПГУ",
    real: "Подан",
}

export const orderCategories = [
    { id: 1, name: "Основной" },
    { id: 2, name: "По иностранцам" },
    { id: 3, name: "По льготникам" },
]

export const levelShortNames = {
    3: "СПО",
    4: "Бак. / Спец.",
    6: "Маг.",
    7: "Асп.",
}

export const invalidTypes = [
    { id: 0, name: "Не инвалид" },
    { id: 1, name: "Наличие ОВЗ" },
    { id: 2, name: "Инвалид с ОВЗ" },
    { id: 3, name: "Инвалид без ОВЗ" },
]

export const orderCategoryLookups = getLookups(orderCategories)